export default {
  color: {
    primary: "#456F87",
    primaryDarken: "#39637B",
    primaryLightest: "#456F8710",
    text: "#1E1D1D",
    textInverse: "#fff",
    bg: "#fff",
    link: "#456F87",
    linkInverse: "#fff",
  },
  searchFormFieldHeaderBorder: "var(--color-primary-darken)",
  searchFormHeaderIconColor: "var(--color-primary-darken)",
  searchFormSubmitHoverBackground: "var(--header-background)",
  searchFormSubmitHoverIconColor: "var(--color-primary-darken)",
  linkColor: "var(--color-link)",
  colorAction: "var(--color-link)",
  colorActionActive: "var(--color-link)",
  headerMainMenuColor: "#fff",
  headerMainTextDecoration: "none",
  headerMainHoverTextDecoration: "underline",
  header: {
    background: "#F1F3F4",
    color: "var(--color-link)",
  },
  headerFlyOut: {
    toggle: {
      borderColor: "var(--color-primary)",
      color: "var(--color-text-inverse)",
      backgroundColor: "var(--color-primary)",
      hover: {
        borderColor: "var(--color-primary-darken)",
        color: "var(--color-text-inverse)",
        backgroundColor: "var(--color-primary-darken)",
      },
    },
  },
  headerMainMenu: {
    background: "var(--color-primary)",
    color: "var(--color-link)",
  },
  footer: {
    background: "var(--color-primary)",
    color: "var(--color-text-inverse)",
    linkColor: "var(--color-text-inverse)",
    dividerBorderTopColor: "var(--color-primary-lightest)",
  },
  gridMenu: {
    backgroundColor: "var(--color-primary)",
  },
  card: {
    background: "var(--color-primary)",
    color: "var(--color-text-inverse)",
    titleColor: "var(--color-text-inverse)",
    metaColor: "var(--color-text-inverse)",
    hover: {
      background: "var(--color-primary-darken)",
      color: "var(--color-text-inverse)",
    },
  },
  boxNavigation: {
    linkBackground: "var(--color-primary)",
    linkColor: "var(--color-link-inverse)",
    linkHoverColor: "var(--color-link)",
    linkHoverBackground: "var(--color-primary-darken)",
    linkCurrentColor: "var(--color-link-inverse)",
  },
  coverLinkColor: "var(--color-primary)",
  teaserMetaColor: "#4e4949",
};
